import HeroImg from "@images/hero-img.png";
import Image from "next/image";
import { useState } from "react";
import Header from "../header";
import ModalForm from "../modal/modalForm";

export default function Hero() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="relative overflow-hidden">
      <Header />

      <main>
        <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-4 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-6">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-24">
                  <h1 className="mt-4 text-4xl tracking-tight font-medium sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">
                      <span className="text-pinto">Build</span> Your Business
                    </span>
                    <span className="block text-pinto">Profitably</span>
                  </h1>
                  <p className="mt-3 text-base sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Pinto is your Small Business CFO that provides financial
                    tools and support to help make your business profitable.
                  </p>
                  <div className="mt-10 sm:mt-12 flex-col flex sm:flex-row">
                    <button
                      onClick={handleOpenModal}
                      className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-pinto-yellow hover:bg-yellow-500 focus:outline-none"
                    >
                      Contact Us
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                <div className="mx-auto max-w-xl px-2 sm:max-w-xl sm:px-6 lg:max-w-xl lg:p-12">
                  <Image src={HeroImg} alt="Financial Guy" priority={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ModalForm isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
}
