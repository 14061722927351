import { LuDot } from "react-icons/lu";
import Screenshot from '/public/financial-reports-screenshot.png';
import Image from 'next/image'
import Link from 'next/link'

const features = [
  {
    name: 'Clean & Organized Bookkeeping.',
    description: 'Every month we reconcile your accounts, categorize your transactions, and provide you with financial statements.',
    icon: LuDot,
  },
  {
    name: 'Monthly Financial Reviews',
    description: '1:1 Meetings & video reviews on where your business financials are.',
    icon: LuDot,
  },
  {
    name: 'Financial Action Plans.',
    description: 'Custom step by step guidelines and goals for your business to get profitable.',
    icon: LuDot,
  },
]

export default function HowItWorks() {
  return (
    <div className="relative bg-white py-24 border-t border-b border-pinto-dark/20 bg-pinto/20">
      <div className="sm:block hidden">
      <span className="px-4 absolute top-12 left-1/2 -ml-16 inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-pinto ring-1 ring-inset ring-pinto-dark/20">
        Learn how we do it
        <br />
      </span> 
      <div className="absolute left-1/2 top-20 -rotate-90 -ml-20">
        <svg xmlns="http://www.w3.org/2000/svg" width="81" height="83" viewBox="0 0 81 83" fill="none" class="dHctcmVsYXRpdmU dHctdy0yMA dHctaC1hdXRv LXR3LXRvcC0y dHctdGV4dC1zbGF0ZS02MDA"><g clip-path="url(#clip0_112_68)"><path d="M79.0279 62.2024C58.1227 60.567 37.0551 52.8379 23.5836 35.8709C19.6389 30.9027 16.5994 23.913 14.6598 17.809C14.25 16.519 14.0629 15.1736 13.8444 13.8392C13.6447 12.6204 8.83154 19.8767 8.22696 20.6903C1.76323 29.3888 8.93024 20.1844 10.9563 16.5611C12.5286 13.7492 13.3857 10.1847 15.3992 7.63962C17.0205 5.59024 20.2035 9.67344 21.5513 10.8281C22.9371 12.0152 33.1749 18.4514 29.1817 20.1187C22.0175 23.1101 14.7009 22.4979 7.21764 22.9016" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path></g><defs><clipPath id="clip0_112_68"><rect width="85" height="29" fill="white" transform="translate(21.4469 0.837036) rotate(46.0556)"></rect></clipPath></defs></svg> 
      </div>
        
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="sm:flex justify-center items-center">
          
          <div className="flex-1">
            <p className="mt-2 text-3xl font-medium tracking-tight text-gray-900 sm:text-4xl">
              How we quickly create a path to <span className="text-pinto">profitability</span>
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Get more time to grow your business using our <span className='font-semibold'>expert</span> help & software to automate your financial process.
            </p>
         
            <ul className="relative space-y-6 mt-8">
              {features.map((feature, index) => (
                <li key={index} className="flex space-x-3 relative">
                  <div className="">
                    <div className="h-8 w-8 bg-white rounded-full text-center p-1">{index+1}</div>
                  </div> 
                  <div>
                    <h3 className="text-lg font-semibold">{feature.name}</h3>
                    <p>{feature.description}</p>
                  </div>
                </li>
              ))}
              <div className="-z-10 absolute left-4 top-4 h-4/5 sm:h-40 border border-dashed border-pinto-dark/20"></div> 
            </ul>
          </div>
         
          <div className="flex-1 ml-6">
            <Image
              src={Screenshot}
              alt="Application Screenshot"
              className="rounded-lg shadow-sm border border-pinto/20"
            />
          </div>

        </div>
      </div>
    </div>
  )
}
