import React from "react";
import Logos from "@/components/home/logos";
import HowItWorks from "@components/home/howItWorks";
import TestimonialBar from "@components/home/testimonialBar";
import CTA from "@components/cta";
import Footer from "@/components/home/footer";
import FeaturesRight from "@/components/home/featuresRight";
import Hero from "@/components/home/hero";
import Blog from "@/components/home/blog";
import Seo from "@/components/seo";
import FreeTools from "@/components/home/freeTools";
// import FeaturesTall from '@/components/home/featuresTall'
// import AppDetails from "@components/home/appDetails";
// import CoreServices from "@/components/home/coreServices";
// import FeaturesLeft from "@/components/home/featuresLeft";

const Home = () => {
  return (
    <>
      <Seo
        title="Pinto Financial | Your Small Business CFO"
        description="Pinto provides financial tools and support to help make your business profitable. Bookkeeping. Forecasting. Reporting. Back Office."
      />

      <div className="">
        <Hero />
        <HowItWorks />
        <FeaturesRight />
        <Logos />
        <FreeTools /> 
        <TestimonialBar />
        <Blog />
        <CTA />
        <Footer />
      </div>
    </>
  );
};

export default Home;
