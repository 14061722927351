import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import Image from 'next/image'
import Link from 'next/link'

const navigation = [
  { name: 'About', href: '/about' },
  { name: 'Free Resources', href: '/free-resources' },
  { name: 'Blog', href: '/blog' },
  { name: 'Pricing', href: '/pricing' },
]

export default function Header({ hideLinks }) {
  return (
    <Popover as="header" className="relative">
      <div className="pt-6">
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
          aria-label="Global"
        >
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link href="/" >
                <div className="cursor-pointer">
                  <span className="sr-only">Pinto Financial</span>
                  <Image
                    height={92}
                    width={253}
                    className="h-8 w-auto sm:h-10"
                    src="/pinto-logo.webp"
                    alt="Pinto Financial"
                    unoptimized
                  />
                </div>
              </Link>
              <div className="-mr-2 flex items-center md:hidden">
                {!hideLinks &&
                <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>}
              </div>
            </div>
            <div className="hidden space-x-8 md:flex md:ml-10">
            </div>
          </div>
          {!hideLinks && <div className="hidden md:flex md:items-center md:space-x-6">
            {navigation.map((item) => (
              <Link key={item.name} href={item.href}>
                <span className="cursor-pointer text-base font-medium text-gray-900 hover:text-pinto hover:underline transition">{item.name}</span>
              </Link>
            ))}
            <span className='text-gray-200'>|</span>

            <Link href="/login">
              <span className="cursor-pointer text-base font-medium text-gray-900 hover:text-pinto hover:underline transition">Login</span>
            </Link>
          </div>}
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-4 pt-4 flex items-center justify-between">
              <div>
                <span className="sr-only">Pinto Financial</span>
                <Image
                  width={88}
                  height={32}
                  className="h-8 w-auto sm:h-10"
                  src="/pinto-logo.webp"
                  alt=""
                  unoptimized
                />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pinto">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="pt-5 pb-6">
              <div className="px-2 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    href={item.href}
                  >
                    <span className="cursor-pointer block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">{item.name}</span>
                  </Link>
                ))}
              </div>
              <div className="mt-6 px-5">
                <p className="text-center text-base font-medium text-gray-500">
                  <Link href="/login">
                    <span className="cursor-pointer text-gray-900 hover:underline">Login</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}



