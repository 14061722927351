import { CheckCircleIcon } from '@heroicons/react/20/solid'
import Link from 'next/link';

const benefits = [
  'Subscription Tracker',
  'Home Office Calculator',
  'Income Statement Generator',
  '13 Week Cash Flow Calculator',
  'Revenue & Profitability Calculator',
  'Recurring Revenue Template',
]

export default function FreeTools() {
  return (
    <div className="bg-white ">
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-pinto px-6 py-16 ring-1 ring-white/10 sm:rounded-xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
             {/* Fix image, make Squared  */}
            
            <img
              className="h-96 w-full flex-none rounded-xl object-cover object-left shadow-sm lg:aspect-square lg:h-auto lg:max-w-sm "
              src="/downloads/income-statement.png"
              alt=""
            />
            <div className="w-full flex-auto">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Free Financial Templates</h2>
              <p className="mt-6 text-lg leading-8 text-gray-100">
                A collection of free tools we&apos;ve created to help improve your business. 
              </p>
              <ul
                role="list"
                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2"
              >
                {benefits.map((benefit) => (
                  <li key={benefit} className="flex gap-x-3">
                    <CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" />
                    {benefit}
                  </li>
                ))}
              </ul>
              <div className="mt-10 flex">
                <Link href="/free-resources" className="text-sm font-semibold leading-6 text-pinto-yellow">
                  See all free templates <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  )
}
